import {Pipe, PipeTransform} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Pipe({
  name: 'hasErrors',
  standalone: true,
  pure: false,
})
export class HasErrorsPipe implements PipeTransform {
  transform(abstractControl: AbstractControl, additionalCondition = true): boolean {
    return !!abstractControl.errors && abstractControl.touched && additionalCondition;
  }
}
