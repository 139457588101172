import {inject, Pipe, PipeTransform} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import {TranslatePipe} from '@shared/pipes/translate.pipe';

@Pipe({
  name: 'inputError',
  standalone: true,
})
export class InputErrorPipe implements PipeTransform {
  private translate = inject(TranslatePipe);

  transform(errors: ValidationErrors | null, format?: string): string {
    if (!errors) return '';
    if (errors['required']) {
      return this.translate.transform('common.inputs.requiredField');
    }
    if (errors['email']) {
      return this.translate.transform('common.inputs.invalidEmailFormat');
    }
    if (errors['pattern']) {
      return `${this.translate.transform('common.inputs.fieldDoesNotMatchFormat')} ${format}`;
    }
    if (errors['minlength']) {
      return `${this.translate.transform('common.inputs.minLengthError')} ${errors['minlength'].requiredLength} ${this.translate.transform('common.values.symbolsShort')}`;
    }
    if (errors['maxlength']) {
      return `${this.translate.transform('common.inputs.maxLengthError')} ${errors['maxlength'].requiredLength} ${this.translate.transform('common.values.symbolsShort')}`;
    }
    if (errors['min']) {
      return `${this.translate.transform('common.inputs.minValueError')} ${errors['min'].min}.`;
    }
    if (errors['max']) {
      return `${this.translate.transform('common.inputs.maxValueError')} ${errors['max'].max}.`;
    }
    if (errors['passwordStrength']) {
      return this.translate.transform('common.inputs.passwordStrengthError');
    }
    if (errors['allLowerCase']) {
      return this.translate.transform('common.inputs.allLowerCaseError');
    }
    console.log(errors[Object.keys(errors)[0]])
    return errors[Object.keys(errors)[0]]?.message || '';
  }

}
